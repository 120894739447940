import React from 'react'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Generic
import BasicPage from '../components/shared/BasicPage/BasicPage'

const TermsAndConditionsPage = (props) => {
  return (
    <>
      <Seo
        title='Privacy and Cookies'
        slug={props.location.pathname}
      />
      <BasicPage>
        <h1 className='h2 text-center mb-12'>Privacy and Cookies</h1>
        <div className='c-prose'>
          <p>Effective date: 31st July, 2024.</p>

          <p>Northcoders is committed to protecting your personal data. This policy explains how and why we use your personal data to make sure you remain informed and in control of your information.</p>
          <p>You can choose not to receive information or to change how we contact you at any time. If you would like to, please let us know by:</p>
          <ul>
            <li>Emailing: <a href='mailto:DPO@Northcoders.com'>DPO@Northcoders.com</a></li>
            <li>Writing to us at our company address:</li>
              <div className='ml-12'>
                <p className='-mb-4'>Northcoders Group</p>
                <p className='-mb-4'>Manchester Technology Centre</p>
                <p className='-mb-4'>103 Oxford Road</p>
                <p className='-mb-4'>Manchester</p>
                <p>M1 7ED</p>
              </div>
            <li>Telephone: 0333 0143 699 (Mon to Fri: 9am-5:30pm. Sat & Sun: Closed)</li>
          </ul>

          <p>This telephone number will put you through to our IVR (Interactive Voice Response). To speak to Reception, please select the ‘Any Other Queries’ option.</p>
          <p>Northcoders will ask its learners or potential learners to ‘opt-in’ to receive information about our products and courses. This means you’ll have the choice as to whether you want to receive these messages.</p>
          <p>We will never sell your personal data. However, we may share your data with specific organisations who work with us, in order to provide the service to you. Please see the ‘Disclosing and Sharing Data’ section for further information on this.</p>
          <p>Any questions you have about this policy or how we use your personal data should be sent to: DPO@northcoders.com or addressed to: The Data Protection Officer, at the postal address given above.</p>

          <h2>About Us</h2>
          <p>Your personal data (i.e. any information which identifies you, or which can be identified as relating to you) will be collected and used by Northcoders which is a private limited company.</p>
          <p>For the purposes of data protection law, Northcoders will be the Data Controller.</p>

          <h3>What Information Do We Collect?</h3>
          <p>We collect data you give to us. For example:</p>
          <ul>
            <li>Personal details (name, address, date of birth, email address, telephone number education details etc) when you sign up to an agreement</li>
            <li>Sensitive information known as special category data (ethnicity, health and disability information, additional learning needs)</li>
            <li>Information about your nationality and right to study in the UK</li>
            <li>Financial information (including details of your course fees and payment information such as credit/debit card or direct debit details)</li>
            <li>Details of your course sponsorship</li>
            <li>Information created by your involvement with Northcoders</li>
            <li>Details of your course and course tier</li>
            <li>Details of your qualifications, skills, experience and employment/education history</li>
            <li>Details of your progress and performance and your learning activities </li>
            <li>Details of your schedule and attendance</li>
            <li>Student enrolment details</li>
            <li>Details of any assessments</li>
            <li>Details of any investigation into breaches of our Code of Ethics</li>
            <li>Details of any complaints you make.</li>
          </ul>

            <p>We may also collect the following sensitive information (known as special category data) about you:</p>
            <ul>
              <li>Equal opportunities monitoring information about your race or ethnicity, religious beliefs, sexual orientation, and political opinions;</li>
              <li>Information about your health, including any medical condition, sickness records, disabilities and/or additional educational needs.</li>
            </ul>

            <h3>Information from Other Organisations</h3>
            <p>We sometimes receive personal data from other organisations, for example, where they have passed us information about people who are interested in our training courses.</p>

            <h3>Young People</h3>
            <p>As an education provider, we regularly process personal data relating to young people who enquire about courses and training opportunities.</p>

            <h3>Sensitive Personal Data</h3>
            <p>We may collect or store data known as sensitive personal data (such as information relating to additional learning needs or ethnicity). We will do this where we have been asked to do so by official Government agencies, or where the information is needed for the assessment of additional education support needs.</p>
            <p>If we do this, we’ll take extra care to ensure your personal data is protected.</p>

            <h3>Accidents or Incidents</h3>
            <p>If an accident or incident occurs in one of our training centres, or involving one of our employees, then we’ll keep a record of this (which may include personal data and sensitive personal data).</p>

            <h3>How Do We Use Personal Information?</h3>
            <p>We only ever use your personal data with your consent, or where it is necessary:</p>
              <ul>
                <li>To enter into, or perform, a contract with you;</li>
                <li>To comply with a legal duty;</li>
                <li>To protect your vital interests: this means, for example, keeping you safe from harm;</li>
                <li>For our own (or a third party’s) lawful interests, provided your rights are not affected;</li>
                <li>We will only use your information for the purpose or purposes it was collected for;</li>
                <li>Administration.</li>
              </ul>
            <p>We use personal data for administrative purposes. This includes:</p>
              <ul>
                <li>Receiving payment (e.g. direct debits or payment card details);</li>
                <li>Maintaining databases of our learners and their educational progress;</li>
                <li>Performing our obligations under training contracts;</li>
                <li>Helping us respect your choices and preferences (e.g. if you ask not to receive marketing materials, we’ll keep a record of this).</li>
              </ul>

            <h3>Research & Analysis</h3>
            <p>We may carry out research and analysis on the data we hold to understand behaviour and responses and identify patterns and trends.</p>
            <p>We evaluate, categorise and profile personal data to tailor services and communications we send out (including targeted advertising) and to prevent unwanted material from being sent to you.</p>

            <h3>Anonymised Data</h3>
            <p>We may combine sets of personal data and change the personal data so that it can no longer be linked to any particular person. This information may then be used to monitor Northcoders’ business performance or to identify trends or patterns within our existing learner database.</p>

            <h3>Disclosing and Sharing Data</h3>
            <p>We will never sell your personal data. If you have opted-in to marketing, we may contact you with information about further opportunities in education.</p>
            <p>We may share personal data with other organisations who provide us with services. For example, we may use an IT supplier to process personal data. However, these activities will be carried out under contracts which have strict requirements to keep your information confidential and secure.</p>

            <table>
              <tr>
                <th>Data Processor</th>
                <th>Purpose of</th>
                <th>Location of Data</th>
              </tr>
              <tr className='text-center'>
                <td>BKSB LTD</td>
                <td>Saas Provider for functional skills assessment</td>
                <td>Hosted in Ireland</td>
              </tr>
              <tr className='text-center'>
                <td>Pellcomp “PICS2</td>
                <td>Saas Provider for Learner Management System</td>
                <td>Hosted in UK Data Centres</td>
              </tr>
            </table>

            <p>Northcoders works closely with assessment organisations who will receive personal data from Northcoders as part of the assessment process.</p>
            <p>These organisations include:</p>
              <ul>
                <li>BCS</li>
                <li>SkillsFirst</li>
              </ul>
            <p>These organisations will use assessment invigilators within remote invigilation organisations based in locations outside the UK and EU.</p>
            <p>The following information will be processed as part of the remote invigilation:</p>
              <ul>
                <li>Picture</li>
                <li>First and Last Name</li>
                <li>Institution or Organisation</li>
                <li>Exam Information</li>
                <li>Time of Exam</li>
                <li>Picture of ID (which may include driver’s licence, address etc, or passport – which would include passport number and country of citizenship)</li>
                <li>Email Address</li>
                <li>Telephone Number</li>
                <li>Google Map Coordinates of IP Address.</li>
              </ul>

            <p>Where we are under a legal or contractual obligation, we may also disclose information with the following:</p>
              <ul>
                <li>With the DfE.</li>
                <li>With your course sponsor.</li>
                <li>With our accountants, auditors, insurers, legal advisors and any other professional advisors.</li>
                <li>With courts and tribunals.</li>
                <li>With government bodies and law enforcement agencies.</li>
                <li>With health and welfare organisations.</li>
                <li>With third parties to whom we may choose to transfer or merge parts of the Trust or our assets. Alternatively, we may seek to acquire other trusts, schools or academies or merge with them. If a change happens to the Trust, then the new management team may use your personal data in the same way as set out in this privacy notice.</li>
              </ul>

            <p>This list is not exhaustive as there are other circumstances where we may also be required to share information, for example:</p>
              <ul>
                <li>To meet our legal obligations.</li>
                <li>In connection with legal proceedings (or where we are instructed to do so by Court order).</li>
              </ul>

            <h3>Marketing</h3>
            <p>Northcoders will ask its learners and contacts to “opt-in” for marketing communications. This means you’ll have the choice as to whether you want to receive these messages.</p>
            <p>We use personal data to communicate with people and promote Northcoders. This includes keeping you up to date with our news and offerings.</p>

            <h3>Social Media</h3>
            <p>We work with external marketing agencies who provide information about people who have registered an interest in Northcoders’ courses and where they have given appropriate consent to be contacted.</p>

            <h3>Digital Media Service Providers</h3>
            <p>We periodically appoint digital marketing agents to conduct business to business marketing activity on our behalf, and such activity may result in the compliant processing of personal information. This includes contacting individuals within organisations which may be interested in the services Northcoders has to offer.  We may also use your photographs and/or other digital images in our marketing material and social media, where you have given us permission to do so.</p>
            <p>We sometimes share your data with trusted third parties (such as Google and LinkedIn) to perform advertising services on our behalf. This helps us make sure that the information we serve you is relevant and keeps you up to date with our products and services.</p>

            <h3>Google</h3>
            <p>Within Google, two of the tools we use are called ‘remarketing’ and ‘customer match’.</p>
            <p>Remarketing is a feature that lets us customise our advertising for users who have previously visited our site. Cookies enable us to serve ads based on your previous usage of the Northcoders site. The ads are served on Google as well as Google’s network of partner sites.</p>
            <p>You can opt out of remarketing by turning off marketing cookies. More information about Google’s data use in personal ads can be found <a href='https://safety.google/privacy/ads-and-data/#:~:text=We%2520never%2520use%2520sensitive%2520information%2520such%2520as%2520health%252C%2520race%252C%2520religion,preferences%2520with%2520My%2520Ad%2520Center.' target='_blank'>here</a>. </p>

            <h3>LinkedIn</h3>
            <p>Within LinkedIn we use ‘remarketing’ and ‘Matched Audiences’:</p>
            <p><u>Remarketing:</u> We use visitor data gathered via the LinkedIn Insights Tag on our site to run customised ads on LinkedIn.</p>
            <p>You can opt out of remarketing by turning off marketing cookies.</p>
            <p><u>Matched Audiences:</u> These are custom audience segments that we use to target advertising campaigns. In some instances, we will share your information (email addresses) with LinkedIn to build these advertising segments.</p>
            <p>More information on how LinkedIn keep your data safe can be found <a href='https://www.linkedin.com/legal/privacy-policy' target='_blank'>here</a>.</p>

            <h3>How Do We Protect Personal Data?</h3>
            <p>We use a variety of physical and technical methods to keep your data safe and to prevent unauthorised access or sharing of your personal information.</p>
            <p>Electronic data and databases are stored on secure computer systems, and we control who has access to information using both physical and electronic means.</p>
            <p>Our employees receive data protection training, and we have a set of detailed data protection procedures which personnel are required to follow when handling personal data.</p>

            <h3>CCTV</h3>
            <p>Some of our premises have CCTV and you may be recorded when you visit them. CCTV is there to help provide security and to protect both you and Northdcoders. CCTV will only be viewed when necessary (e.g. to detect or prevent crime) and footage is only stored temporarily. Unless it is flagged to review, CCTV will be recorded over.</p>
            <p>Northcoders complies with the Information Commissioner’s Office CCTV Code of Practice. We display notices to let you know when CCTV is in use.</p>

            <h3>Storage of Personal Data</h3>
            <p><u>Where Northcoders information is stored </u></p>
            <p>Northcoders operations are based in the UK, and we store our data primarily within the UK and European Economic Area (EEA).</p>
            <p>Northcoders will occasionally use services outside of the UK and EEA to process personal data, but only where the service ensures an adequate level of protection and where appropriate safeguards have been provided.</p>
            <p>Northcoders has put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

            <p><u>How long does Northcoders keep information for? </u></p>
            <p>We will only use and store information for as long as it is needed for the purposes it was collected for. How long we keep information depends on the information and what it’s used for. For example, if you ask us not to send you marketing emails, we will stop storing your emails for marketing purposes (though we’ll keep a record of your preference not to be emailed).</p>
            <p>We continually review what information we hold and delete what is no longer required. We never store payment card information.</p>

            <p><u>How do we ensure that you remain in control of your data?</u></p>
            <p>We want to ensure you remain in control of your personal data and that you understand your legal rights which include:</p>
              <ul>
                <li>The right to ask and be told whether or not we have your personal data, and if we do, to obtain a copy of the personal data we hold (this is known as a subject access request);</li>
                <li>The right to have your data deleted (though this will not apply where it is necessary for us to continue to use the data to administer your training course);</li>
                <li>The right to have incorrect data amended;</li>
                <li>The right to object to your data used for marketing purposes or profiling.</li>
              </ul>
            <p>Please note that there are some exceptions to the rights above and, although we will always try to respond to your satisfaction, there may be situations where we are unable to do so.</p>
            <p>If you would like further information on your rights, please write of email our Data Protection Officer at the addresses given at the start of this policy.</p>
            <p>We can provide you with a subject access request form template which includes guidance on how to make your request, and will help us respond more quickly. Please contact us for a copy of this.</p>

            <h3>Complaints</h3>
            <p>You can complain to Northcoders directly by contacting our Data Protection Officer, using the contact details set out at the start of this policy.</p>
            <p>If you would like to make a complaint which does not directly relate to your data protection and privacy rights, please see Northcoders Complaints Policy.</p>
            <p>If you are not happy with our response, or you believe that your data protection or privacy rights have been infringed, you can complain to the UK Information Commissioner’s Office which regulates and enforces data protection law in the UK. Details of how to do this can be found at <a href='https://www.ico.org.uk' target='_blank'>www.ico.org.uk</a>.</p>

            <h3>Cookies and Links to Other Websites</h3>
            <p><u>Cookies</u></p>
            <p>For information relating to cookies and to set your preferences, please refer to our Privacy Preferences Page.</p>

            <p><u>Links to other websites</u></p>
            <p>Our website contains hyperlinks to many other websites. We are not responsible for the content or functionality of any of those external websites (but please let us know if a link is not working by using the ‘Contact Us’ link at the top of our web page).</p>
            <p>If an external website requests personal information from you, the information you provide will not be covered by the Northcoders’ Privacy Policy. We suggest you read the Privacy Policy of any website before providing any personal information.</p>
            <p>When purchasing goods or services from any of the businesses that our site links to, you will entering into a contract with them (agreeing to their terms and conditions) and not with Northcoders.</p>

            <h3>Changes to this Privacy Policy</h3>
            <p>We’ll amend this Privacy Policy from time to time, to ensure it remains up-to-date and accurately reflects how and why we use your personal data. The current version of our Privacy Policy will always be posted on our website.</p>
      </div>
        {/* <div className='c-prose'>
          <p>Effective date: March 8th, 2018</p>
          <p>Northcoders Limited ("us", "we", or "our") operates the https://northcoders.com/ website (the "Service").</p>
          <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
          <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://northcoders.com/</p>
          <h2><strong>Definitions</strong></h2>
          <h3><strong>Personal Data</strong></h3>
          <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
          <h3><strong>Usage Data</strong></h3>
          <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          <h3><strong>Cookies</strong></h3>
          <p>Cookies are small pieces of data stored on a User’s device.</p>
          <h3><strong>Data Controller</strong></h3>
          <p>Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
          <p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
          <h3><strong>Data Processor (or Service Providers)</strong></h3>
          <p>Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
          <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
          <h3><strong>Data Subject</strong></h3>
          <p>Data Subject is any living individual who is the subject of Personal Data.</p>
          <h3><strong>User</strong></h3>
          <p>The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
          <h2><strong>Information Collection And Use</strong></h2>
          <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
          <h3><strong>Types of Data Collected</strong></h3>
          <h4><strong><em>Personal Data</em></strong></h4>
          <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Cookies and Usage Data</p>
            </li>
          </ul>
          <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
          <p>All our website financial transactions are handled through our payment services provider, Stripe. You can review the provider's privacy policy at https://stripe.com/gb/privacy. We will share information with our payment services provider only to the extent necessary for the purposes of processing payments you make via our website, refunding such payments and dealing with complaints and queries relating to such payments and refunds.</p>
          <h4><strong><em>Usage Data</em></strong></h4>
          <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <h4><strong><em>Tracking Cookies Data</em></strong></h4>
          <p><strong>ABOUT COOKIES</strong></p>
          <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
          <p>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
          <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
          <p>Cookies can be used by web servers to identify and track users as they navigate different pages on a website and identify users returning to a website.</p>
          <p><strong>OUR COOKIES</strong></p>
          <p>We may use session and persistent cookies on our website, a list of which we will provide on request at hello@northcoders.com.</p>
          <p><strong>BLOCKING COOKIES</strong></p>
          <p>Most browsers allow you to refuse to accept cookies; for example:</p>
          <p>in Internet Explorer (version 11), you can block cookies using the cookie handling override settings available by clicking "Tools", "Internet Options", "Privacy" and then "Advanced";</p>
          <p>in Firefox (version 39), you can block all cookies by clicking "Tools", "Options", "Privacy", selecting "Use custom settings for history" from the drop-down menu, and unticking "Accept cookies from sites"; and</p>
          <p>in Chrome (version 44), you can block all cookies by accessing the "Customise and control" menu, and clicking "Settings", "Show advanced settings" and "Content settings", and then selecting "Block sites from setting any data" under the "Cookies" heading.</p>
          <p>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
          <p>If you block cookies, you will not be able to use all the features on our website.</p>
          <p><strong>DELETING COOKIES</strong></p>
          <p>You can delete cookies already stored on your computer; for example:</p>
          <p>in Internet Explorer (version 11), you must manually delete cookie files (you can find instructions for doing so at http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);</p>
          <p>in Firefox (version 39), you can delete cookies by clicking "Tools", "Options" and "Privacy", then selecting "Use custom settings for history" from the drop-down menu, clicking "Show Cookies", and then clicking "Remove All Cookies"; and</p>
          <p>in Chrome (version 44), you can delete all cookies by accessing the "Customise and control" menu, and clicking "Settings", "Show advanced settings" and "Clear browsing data", and then selecting "Cookies and other site and plug-in data" before clicking "Clear browsing data"</p>
          <p>Deleting cookies will have a negative impact on the usability of many websites.</p>
          <p><strong>COOKIE PREFERENCES</strong></p>
          <p>You can manage your preferences relating to the use of cookies on our website by emailing us at hello@northcoders.com.</p>
          <h2><strong>Use of Data</strong></h2>
          <p>Northcoders Limited uses the collected data for various purposes:</p>
          <ul>
            <li>
              <p>To provide and maintain our Service</p>
            </li>
            <li>
              <p>To notify you about changes to our Service</p>
            </li>
            <li>
              <p>To allow you to participate in interactive features of our Service when you choose to do so</p>
            </li>
            <li>
              <p>To personalise our website to you</p>
            </li>
            <li>
              <p>To send you non-marketing commercial communications</p>
            </li>
            <li>
              <p>To send you email notifications that you have specifically requested</p>
            </li>
            <li>
              <p>To send you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter);</p>
            </li>
            <li>
              <p>To send you marketing communications relating to our business which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications);</p>
            </li>
            <li>
              <p>To provide customer support</p>
            </li>
            <li>
              <p>To provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information)</p>
            </li>
            <li>
              <p>To gather analysis or valuable information so that we can improve our Service</p>
            </li>
            <li>
              <p>To monitor the usage of our Service</p>
            </li>
            <li>
              <p>To detect, prevent and address technical issues</p>
            </li>
            <li>
              <p>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</p>
            </li>
          </ul>
          <h2><strong>Retention of Data</strong></h2>
          <p>Northcoders Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p>Northcoders Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
          <h2><strong>Transfer Of Data</strong></h2>
          <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
          <p>If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Kingdom and process it there.</p>
          <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
          <p>Northcoders Limited will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
          <h2><strong>Disclosure Of Data</strong></h2>
          <p>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this policy.</p>
          <p>We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</p>
          <p>We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing.</p>
          <p>Except as provided in this policy, we will not provide your personal information to third parties.</p>
          <h3><strong>Business Transaction</strong></h3>
          <p>If Northcoders Limited is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
          <h3><strong>Disclosure for Law Enforcement</strong></h3>
          <p>Under certain circumstances, Northcoders Limited may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h3><strong>Legal Requirements</strong></h3>
          <p>Northcoders Limited may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>
              <p>To comply with a legal obligation</p>
            </li>
            <li>
              <p>To protect and defend the rights or property of Northcoders Limited</p>
            </li>
            <li>
              <p>To prevent or investigate possible wrongdoing in connection with the Service</p>
            </li>
            <li>
              <p>To protect the personal safety of users of the Service or the public</p>
            </li>
            <li>
              <p>To protect against legal liability</p>
            </li>
          </ul>
          <h2><strong>Security Of Data</strong></h2>
          <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
          <h2><strong>Your Rights</strong></h2>
          <p>Northcoders Limited aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
          <p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
          <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
          <p>In certain circumstances, you have the right:</p>
          <ul>
            <li>
              <p>To access and receive a copy of the Personal Data we hold about you</p>
            </li>
            <li>
              <p>To rectify any Personal Data held about you that is inaccurate</p>
            </li>
            <li>
              <p>To request the deletion of Personal Data held about you</p>
            </li>
          </ul>
          <p>You have the right to data portability for the information you provide to Northcoders Limited. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.</p>
          <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
          <h2><strong>Service Providers</strong></h2>
          <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
          <h3><strong>Analytics</strong></h3>
          <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
          <p><strong>ANALYTICS COOKIES</strong></p>
          <p>We use Google Analytics to analyse the use of our website.</p>
          <p>Our analytics service provider generates statistical and other information about website use by means of cookies.</p>
          <p>The analytics cookies used by our website have the following names: _ga, _gat, __utma, __utmt, __utmb, __utmc, __utmz and __utmv.</p>
          <p>The information generated relating to our website is used to create reports about the use of our website.</p>
          <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
          <p>Our analytics service provider's privacy policy is available at: http://www.google.com/policies/privacy/.</p>
          <p><strong>THIRD PARTY COOKIES</strong></p>
          <p>Our website also uses third party cookies, a list of which we will provide on request at hello@northcoders.com.</p>
          <h3><strong>Behavioral Remarketing</strong></h3>
          <p>Northcoders Limited uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
          <p><strong>Google AdWords</strong></p>
          <p>Google AdWords remarketing service is provided by Google Inc.</p>
          <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href='http://www.google.com/settings/ads'>http://www.google.com/settings/ads</a></p>
          <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
          <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href='http://www.google.com/intl/en/policies/privacy/'>http://www.google.com/intl/en/policies/privacy/</a></p>
          <p><strong>Twitter</strong></p>
          <p>Twitter remarketing service is provided by Twitter Inc.</p>
          <p>You can opt-out from Twitter's interest-based ads by following their instructions: <a href='https://support.twitter.com/articles/20170405'>https://support.twitter.com/articles/20170405</a></p>
          <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href='https://twitter.com/privacy'>https://twitter.com/privacy</a></p>
          <p><strong>Facebook</strong></p>
          <p>Facebook remarketing service is provided by Facebook Inc.</p>
          <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href='https://www.facebook.com/help/164968693837950'>https://www.facebook.com/help/164968693837950</a></p>
          <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook: <a href='https://www.facebook.com/help/568137493302217'>https://www.facebook.com/help/568137493302217</a></p>
          <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href='http://www.aboutads.info/choices/'>http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href='http://youradchoices.ca/'>http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href='http://www.youronlinechoices.eu/'>http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>
          <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href='https://www.facebook.com/privacy/explanation'>https://www.facebook.com/privacy/explanation</a></p>
          <p><strong>AdRoll</strong></p>
          <p>AdRoll remarketing service is provided by Semantic Sugar, Inc.</p>
          <p>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page: <a href='http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false'>http://info.evidon.com/pub_info/573?v=1nt=1nw=false</a></p>
          <p>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page: <a href='http://www.adroll.com/about/privacy'>http://www.adroll.com/about/privacy</a></p>
          <h2><strong>Links To Other Sites</strong></h2>
          <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
          <h2><strong>Children's Privacy</strong></h2>
          <p>Our Service does not address anyone under the age of 13 ("Children").</p>
          <p>We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
          <h2><strong>Changes To This Privacy Policy</strong></h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          <h2><strong>Contact Us</strong></h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul>
            <li>
              <p>By email: privacy@northcoders.com</p>
            </li>
            <li>
              <p>By visiting this page on our website: </p>
              <a href='https://northcoders.com/contact-us'>https://northcoders.com/contact-us</a>
            </li>
          </ul>
          <p><strong>DATA PROTECTION REGISTRATION</strong></p>
          <p>We are registered as a data controller with the UK Information Commissioner's Office.</p>
          <p><strong>OUR DETAILS</strong></p>
          <p>This website is owned and operated by Northcoders Limited.</p>
          <p>We are registered in England and Wales under registration number 09912193 and our registered office is at 6th Floor, Blackfriars House, Parsonage, Manchester M3 2JA.</p>
          <p>Our principal place of business is at Manchester Technology Centre, 103 Oxford Rd, Manchester M1 7ED.</p>
          <p>You can contact us by writing to the business address given above, by using our website contact form, by email to hello@northcoders.com or by telephone on 0333 050 4368.</p>
        </div> */}
      </BasicPage>
    </>
  )
}

export default TermsAndConditionsPage
